import Vue from 'vue'
import VueRouter from 'vue-router'
// import DienstPlan from '../views/DienstPlan.vue'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/dash',
    name: 'dash',
    component: () => import('../views/DienstPlan.vue'),
    meta: {
      authRequired: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
