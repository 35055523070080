import './firebase'
import  firebase from 'firebase/app';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { firestorePlugin, rtdbPlugin } from 'vuefire';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import './assets/custom-vars.scss'
/* import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css' */


Vue.config.productionTip = false
Vue.prototype.$firebase = firebase

Vue.use(firestorePlugin);
Vue.use(rtdbPlugin);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      alert('You must be logged in to see this page');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
